.highlight__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    max-inline-size: 40ch;
    text-wrap: pretty;
  }

  & .arrow-link:focus-visible {
    outline-color: var(--color-beige);
  }

  & .button {
    --color: var(--color-white);
    --hover-color: var(--color-olive-text);
  }
}

.highlight__image {
  width: min(100% - var(--space-s-m) * 2, var(--wrapper-max, 82rem));
  margin-inline: auto;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  &::before {
    position: absolute;
    content: "";
    width: 1000%;
    height: 33%;
    background: var(--color-olive);
    bottom: 0;
    left: -50%;
    z-index: -1;
  }
}
