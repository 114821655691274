.text-image__content {
  display: flex;
  flex-direction: column-reverse;
  column-gap: var(--space-3xl);
  row-gap: var(--space-xl);
  align-items: flex-start;

  @media (--md) {
    flex-direction: row-reverse;

    & .prose {
      margin-block-start: 4em;
      margin-block-end: 4em;
      max-width: 45rem;
    }
  }
}

.text-image__picture {
  @media (--md) {
    flex-basis: 24rem;
    position: sticky;
    top: calc(var(--header-size) + 6rem);
  }
}

.text-image + .text-image {
  .text-image__content {
    flex-direction: column;

    @media (--md) {
      flex-direction: row;
    }
  }
}
