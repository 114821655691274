.header {
  font-size: var(--text-step--1);
  position: sticky;
  z-index: 30;
  block-size: var(--header-size);
  display: flex;
  align-items: center;
  margin-top: var(--space-l);
  transition: background-color 0.3s, margin-top 0.2s;
  top: 0;

  &:has(.header__nav-toggle[aria-expanded="true"]) {
    margin-top: 0;
  }

  &[data-stuck] {
    background-color: var(--color-white);
  }

  & svg {
    height: 2.5rem;
    aspect-ratio: 17.5/3;

    @media (--xl) {
      height: 3rem;
    }
  }

  & a {
    &:focus-visible {
      outline: 2px solid var(--color-blue-dark);
      outline-offset: 2px;
    }
  }
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-l);
}

/* Transition between white and colored logo */
.home {
  .header__logo {
    display: grid;

    > svg {
      transition: opacity 0.3s;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    /* White */
    > svg:first-child {
      opacity: 1;
    }

    /* Colored */
    > svg:last-child {
      opacity: 0;
    }
    .header[data-stuck] & {
      > svg:first-child {
        opacity: 0;
      }

      > svg:last-child {
        opacity: 1;
      }
    }
    @media (--xl-max) {
      header:has(.header__nav-toggle[aria-expanded="true"]) & {
        > svg:first-child {
          opacity: 0;
        }

        > svg:last-child {
          opacity: 1;
        }
      }
    }
  }
}

.header__nav {
  a {
    border-radius: 999px;
    transition: background 0.3s ease, color 0.3s ease;

    @media (any-pointer: fine) and (any-hover: hover) {
      &:hover {
        background: var(--color-beige);
        color: var(--color-blue-dark);
      }
    }
  }

  @media (--xl-max) {
    transition: 0.5s opacity var(--ease-out-circ) allow-discrete,
      0.5s display var(--ease-out-circ);
    display: none;
    opacity: 1;

    .header__nav-toggle[aria-expanded="true"] + & {
      display: flex;
      position: fixed;
      inset: 0;
      height: 100%;
      background-color: white;
      z-index: -1;
      padding-block-start: calc(var(--header-size) + var(--space-m));
      overflow-y: auto;
      opacity: 1;
      translate: 0 0;

      @starting-style {
        opacity: 0;
        translate: 0 1rem;
      }
    }
  }

  & ul {
    display: flex;

    @media (--xl-max) {
      flex-direction: column;
      width: min(100%, 32rem);
      gap: var(--space-xs);
      margin-inline: auto;
    }
  }

  & li {
    display: flex;
    justify-content: stretch;

    @media (--xl-max) {
      /* Staggered animation */
      --stagger-delay: calc(var(--i) * 0.05s);

      transition: 1s var(--ease-out-circ) var(--stagger-delay);
      transition-property: opacity, translate;
      opacity: 1;
      translate: 0 0;

      @starting-style {
        opacity: 0;
        translate: 0 2rem;
      }
    }
  }
}

.header__nav-link {
  text-transform: uppercase;
  font-weight: var(--weight-bold);
  letter-spacing: var(--tracking-s);
  text-decoration: none;
  min-block-size: 2.75rem;
  padding-inline: var(--space-s);
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  position: relative;
  transition: color 0.3s;

  &:not(.header__contact):is([aria-current="page"], [data-state="active"]) {
    &::before {
      content: "";

      --size: 0.375rem;
      inline-size: var(--size);
      block-size: var(--size);
      position: absolute;
      translate: -50% 0;
      border-radius: 50%;
      background-color: var(--color-blue-dark);
      @media (--xl-max) {
        left: 0.25em;
      }

      @media (--xl) {
        top: 0.05em;
        left: 50%;
      }
    }
  }

  @media (--xl-max) {
    padding-inline: var(--space-xs);
    margin-inline: calc(var(--space-s) - var(--space-xs));
    min-block-size: 3rem;
    font-size: var(--text-step-0);
    flex-grow: 1;
    transition: background-color 0.2s;

    &:not(.header__contact) {
      &:hover,
      &:focus-visible {
        background-color: rgb(0 0 0 / 0.07);
      }
    }
  }

  @media (--xl) {
    .home .header:not([data-stuck]) &:not(.header__contact) {
      color: var(--color-white);

      @media (any-pointer: fine) and (any-hover: hover) {
        &:hover {
          color: var(--color-blue-dark);
        }
      }
    }
  }
}

.header__contact {
  background-color: var(--color-blue-light);
  border-radius: 99px;
  border-bottom-left-radius: 0;
  transition-duration: 0.2s;
  transition-property: background-color, color;
  justify-content: center;

  &:hover,
  &:focus-visible {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
  }

  &:focus-visible {
    outline: 2px solid var(--color, var(--_fallback-color));
    outline-offset: 2px;
  }

  margin-left: var(--space-s);

  @media (--xl-max) {
    margin-inline: var(--space-s);
    margin-block-start: var(--space-xs);
  }
}

.header__nav-toggle {
  @media (--xl) {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 3rem;
  aspect-ratio: 1;
  cursor: pointer;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: -0.625rem;

  &:focus-visible {
    outline: 2px solid var(--color-blue-dark);
    outline-offset: 2px;
  }

  > span {
    display: inline-block;
    position: absolute;
    background-color: var(--color-blue-dark);
    width: 1.75rem;
    height: 0.125rem;
    transition: 0.3s translate 0.3s, 0.3s rotate, 0.3s opacity 0.2s;
    transform-origin: center;

    .home
      .header:not([data-stuck], :has(.header__nav-toggle[aria-expanded="true"]))
      & {
      background-color: var(--color-white);
    }

    &:nth-of-type(1) {
      translate: 0 -0.45rem;
    }

    &:nth-of-type(3) {
      translate: 0 0.45rem;
    }
  }

  &[aria-expanded="true"] > span {
    transition: 0.3s translate, 0.3s rotate 0.3s, 0.3s opacity 0.01s;
  }

  &[aria-expanded="true"] > span:nth-of-type(1) {
    translate: 0 0;
    rotate: -45deg;
  }

  &[aria-expanded="true"] > span:nth-of-type(2) {
    opacity: 0;
  }

  &[aria-expanded="true"] > span:nth-of-type(3) {
    translate: 0 0;
    rotate: 45deg;
  }
}
@media (--xl-max) {
  body:has(.header__nav-toggle[aria-expanded="true"]) {
    overflow: hidden;
  }
}
