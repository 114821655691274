.home-hero-wrapper {
  padding-block-end: var(--space-2xl-3xl);
  background-color: white;
  box-shadow: 0 -20rem 0 0 var(--color-olive);
}

.home-hero {
  padding-block-start: var(--space-xl-2xl);
  background-color: var(--color-olive);
  padding-block-end: 0;
}

.home-hero__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(28rem, 100%), 1fr));
  column-gap: var(--space-l-xl);
  row-gap: var(--space-m);
  align-items: center;
  z-index: 1;
}

.home-hero__image {
  margin-block-end: calc(-1 * var(--space-2xl-3xl));

  @media (max-width: 60rem) {
    aspect-ratio: 4/3;
    object-fit: cover;
    object-position: top center;
  }

  @media (max-width: 40rem) {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top center;
  }
}
