dialog {
  border-radius: var(--radius-l) var(--radius-l) var(--radius-l) 0;
  margin-inline: auto;
  border: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  .close-modal {
    background: var(--color-white);
    padding: var(--space-2xs);
    border-radius: 999px;
    position: absolute;
    top: var(--space-s-m);
    right: var(--space-s-m);
  }

  &::backdrop {
    background: var(--color-olive);
    opacity: 0.9;
  }
}
