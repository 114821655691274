.arrow-link {
  background-color: var(--color-beige);
  display: inline-flex;
  text-decoration: none;
  color: var(--color-olive);
  justify-content: center;
  align-items: center;
  padding-inline: 0.5em 0.75em;
  border-radius: 99px;
  line-height: 1.6;
  gap: 0.25em;
  padding-block-end: 0.05em;

  & svg {
    --size: 1.5rem;
    width: var(--size);
    height: var(--size);
    margin-block-end: -0.025em;
  }

  &:focus-visible {
    outline: 2px solid var(--color-olive);
    outline-offset: 2px;
  }

  &.small {
    padding: 0 0.5em;
    gap: 0;

    svg {
      --size: 1rem;
    }
  }
}
