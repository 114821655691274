@font-face {
  font-family: "Wix Madefor Text Variable";
  src: url("/assets/fonts/WixMadeforText-VariableFont_wght.woff2")
    format("woff2");
  font-weight: 400 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Wix Madefor Text Variable";
  src: url("/assets/fonts/WixMadeforText-Italic-VariableFont_wght.woff2")
    format("woff2");
  font-weight: 400 800;
  font-display: swap;
  font-style: italic;
}
