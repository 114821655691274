.publication {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: var(--space-m-l);
  border-bottom: 2px solid var(--color-blue-light);
  align-items: flex-start;

  h2,
  h3,
  p {
    max-width: clamp(60ch, 80vw, 80ch);
  }

  p {
    text-wrap: balance;
  }

  &:last-child {
    //border-bottom: 0;
  }

  img {
    flex-shrink: 0;
    width: clamp(10rem, 20%, 30rem);
    height: auto;
  }

  @media (min-width: 40rem) {
    flex-direction: row;
    align-items: center;
  }
}
