.nextup {
  padding: var(--space-m);
  border-radius: 999px;
  color: var(--color-white);
  display: grid;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  margin-inline: auto;
  column-gap: var(--space-s);
  row-gap: 0;
  flex-wrap: wrap;

  h3 {
    grid-row: 1;
  }

  .nextup__date {
    grid-row: 2;
    grid-column: 2;
  }

  @media (min-width: 40rem) {
    .nextup__date {
      grid-row: 1;
      grid-column: 3;
    }
  }
}

.nextup__icon {
  background: var(--color-white);
  padding: var(--space-2xs);
  width: fit-content;
  border-radius: 999px;
  flex-shrink: 0;
  grid-row: 1;
}
