.coaching-feature {
  > .section:first-child {
    padding-block-end: var(--space-3xl-4xl);
  }

  > .section:last-child {
    padding-block-start: 0;
  }
}

.coaching-feature__cards {
  margin-top: calc(var(--space-xl) * -1);
}

.coaching-feature__card {
  padding: var(--space-m-l);
  background-color: var(--color-white);
  flex-basis: 26rem;
  flex-grow: 1;
  border: 1px solid rgb(0 0 0 / 0.05);

  & h3 {
    display: flex;
    gap: var(--space-s);
    align-items: center;

    & svg {
      color: var(--color-olive);
      inline-size: clamp(3rem, 2.5rem + 2vw, 4rem);
      aspect-ratio: 1;
    }
  }
}

.coaching-feature__reasons {
  padding: var(--space-l);
  background-color: var(--color-blue-light);
  border-radius: 3rem 3rem 3rem 0;

  & h3 {
    flex-basis: 25ch;
  }

  & ul li {
    &::marker {
      content: "…";
    }
  }
}
