form {
  .form-control {
    display: flex;
    flex-direction: column;
    gap: var(--space-3xs);
  }

  label {
    padding-left: 1rem;
  }

  input,
  textarea {
    width: 100%;
    border: none;
    padding: 0.5rem 1rem;
    font-size: var(--text-step-0);

    &:focus-visible {
      outline: 2px solid var(--color-blue-dark);
      outline-offset: 2px;
    }
  }

  input[type="text"],
  input[type="email"] {
    border-radius: 999px;
    webkit-appearance: none;
  }

  textarea {
    border-radius: var(--radius-m);
  }

  input[type="submit"] {
    background: transparent;
  }
}

.form__hint {
  line-height: 1.7;
  margin-inline: auto;
  max-width: 40ch;
}

.form-ruler,
.form-ruler ~ .form-control {
  display: none;
}
