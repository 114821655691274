.teaser {
}

.teaser__header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
}

.teaser__image {
  grid-row: 1 / 3;
  grid-column: 1 / -1;
  aspect-ratio: 640 / 370;
  width: 100%;
  border-bottom-right-radius: 0;
  object-fit: cover;
}

.teaser__headline {
  grid-row: 2 / -1;
  grid-column: 1 / 2;
  align-self: end;
  background-color: var(--color-beige);
  border-top-right-radius: var(--radius-l);
  padding-block-start: 1em;
  padding-inline-start: var(--space-s);
  padding-inline-end: var(--radius-l);
  position: relative;
  min-height: 3em;

  > .corner {
    --corner-color: var(--color-beige);
    --corner-size: var(--radius-l);
    position: absolute;
  }

  > .corner:first-child {
    bottom: 100%;
    left: 0;
  }

  > .corner:last-child {
    bottom: 0;
    left: 100%;
  }
}

.teaser__content {
  padding-block-start: var(--space-m-l);
  padding-inline-start: var(--space-s);
  display: flex;
  gap: var(--space-m);
  flex-wrap: wrap;

  > .prose {
    flex-basis: 26rem;
    flex-grow: 1;
  }

  > .button {
    flex-basis: 10rem;
  }
}
