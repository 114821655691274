.newsletter {
  isolation: isolate;
}

.newsletter__form {
  margin-inline: auto;
  max-inline-size: 50rem;
  z-index: 1;
  position: relative;
}

.newsletter__input-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: var(--color-white);
  border-radius: 1.375rem;
  row-gap: var(--space-3xs);

  &:has(.newsletter__input:focus-visible) {
    outline: 2px solid var(--color-beige);
    outline-offset: 2px;
  }

  & button.button {
    background: var(--color-beige);
    color: var(--color-blue-dark);
    border-color: var(--color-beige);
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.newsletter__input {
  flex-basis: 40ch;
  flex-grow: 999;
  border-radius: 99px;
  background: transparent;
  border: none;
  height: 2.75rem;
  padding-inline: var(--space-s);
  font-size: var(--text-step-0);

  &:focus-visible {
    outline: 0;
  }
}

.newsletter__trust {
  padding: var(--space-l);
  padding-top: calc(var(--space-2xl) + var(--space-xl));
  margin-top: calc(var(--space-2xl) * -1);
  z-index: 0;
  position: relative;
  justify-content: center;
}

.newsletter__logos {
  display: flex;
  gap: 1rem;

  > img {
    height: 4rem;
  }
}

.newsletter__promise {
  flex-basis: 50ch;
  flex: 1;
  flex-shrink: 0;
}
