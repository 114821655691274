.text-hero {
  padding-block: var(--space-3xl-4xl);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .wrapper {
    max-width: 80ch;
  }

  > p {
    max-inline-size: 40ch;
    text-wrap: balance;
  }

  &:has(+ .anchor-nav) {
    padding-block-end: var(--space-l);
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    width: clamp(20rem, 20vw, 40rem);
    height: clamp(15rem, 30vw, 40rem);
  }

  &::before {
    background-position: top left;
    background-image: url("/assets/images/hero-graphic-01.svg");
    top: 2rem;
    left: 0;

    @media (--xl) {
      top: -12rem;
    }
  }

  &::after {
    background-position: bottom right;
    background-image: url("/assets/images/hero-graphic-02.svg");
    top: 10rem;
    right: 0;

    @media (--xl) {
      top: -5rem;
    }
  }
}
