.person-card {
  --radius: 2.5rem;
}

.person-card__image {
  img {
    border-radius: var(--radius) var(--radius) 0 0;
    object-fit: cover;
    object-position: top center;
    width: 100%;
    aspect-ratio: 3/4;
    height: auto;
  }
}

.person-card__content {
  position: relative;
  border-radius: 0 var(--radius) 0 0;
  margin-top: calc((var(--text-step-4) + var(--radius)) * -1);
  padding: var(--space-s);
  background: var(--color-white);
  width: calc(100% - var(--radius));

  &::before,
  &::after {
    content: "";
    width: var(--radius);
    height: var(--radius);
    background: radial-gradient(
      200% 200% at 100% 0%,
      rgba(255, 0, 0, 0) 50%,
      #ffffff 50%
    );
    position: absolute;
  }

  &::before {
    bottom: 100%;
    left: 0;
  }

  &::after {
    top: calc((var(--text-step-4) + var(--radius)) - var(--radius));
    left: 100%;
  }
}
