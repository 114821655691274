.logo-list {
  align-items: center;

  li {
    position: relative;

    a {
      inset: 0;
      position: absolute;
    }
  }

  img {
    max-height: 5rem;
    max-width: 12rem;
    object-fit: contain;
  }

  @media (--md) {
    img {
      max-height: 5rem;
      max-width: 15rem;
    }
  }
}
