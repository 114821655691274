:where(.prose) {
  color: var(--color, var(--color-blue-dark));
  font-size: var(--text-step-1);
  line-height: var(--leading-normal);

  & :where(h1, h2, h3, h4, h5, h6) {
    line-height: var(--leading-tight);
    text-wrap: pretty;
    margin-block-end: 0.5em;
  }

  & :where(h1, h2) {
    font-weight: var(--weight-regular);
  }

  & :where(h1) {
    font-size: var(--text-step-5);
  }

  & :where(h2) {
    font-size: var(--text-step-4);
  }

  & :where(h3) {
    font-size: var(--text-step-3);
    font-weight: var(--weight-semibold);
  }

  & :where(h4) {
    font-size: var(--text-step-2);
    font-weight: var(--weight-bold);
  }

  & :where(h5) {
    font-size: var(--text-step-1);
    font-weight: var(--weight-ultrabold);
  }

  & > * + * {
    margin-block-start: 1.25em;
  }

  & :where(p, ul, ol, blockquote, pre) {
    max-inline-size: 70ch;
    text-wrap: pretty;
  }

  & :where(button.js-dialog-trigger) {
    text-decoration: underline;
  }

  & :where(ul, ol) {
    padding-inline-start: 1.5em;

    > li {
      padding-inline-start: 0.25em;

      &::marker {
        color: color-mix(in srgb, var(--color), transparent 30%);
      }
    }

    > li + li {
      margin-block-start: 0.375em;
    }

    &.checkmark-list,
    &.arrow-list {
      padding-inline-start: 0;

      & li {
        list-style: none;
        padding-inline-start: 1.75em;
        gap: 0.8em;
        position: relative;

        &::before {
          content: "";
          inset-inline-start: 0;
          inset-block-start: 0.25em;
          position: absolute;
          display: inline-block;
          width: 1em;
          height: 1em;
          background-color: var(--color-olive);
          border-radius: 99px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 10'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M11.1247.219168c.4313.34501.5012.974302.1562 1.405562l-6.00003 7.5c-.17769.22211-.44156.35805-.72557.37378-.28401.01573-.56128-.09023-.76241-.29137l-3.499997-3.5c-.3905241-.39052-.3905241-1.02369 0-1.41421.390524-.39052 1.023687-.39052 1.414217 0L4.41708 7.0029 9.71913.375342c.34497-.4312622.97427-.501184 1.40557-.156174Z' clip-rule='evenodd'/%3E%3C/svg%3E");
          background-size: 55%;
          background-repeat: no-repeat;
          background-position: center 55%;
        }

        + li {
          margin-block-start: 0.8em;
        }
      }
    }

    &.arrow-list {
      & li {
        &::before {
          background-size: 50%;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%2213%22%20viewBox%3D%220%200%2011%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%206.5h9m0%200-4.875-5M10%206.5l-4.875%205%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
        }
      }
    }
  }

  blockquote {
    font-size: var(--text-step-3);
    display: flex;
    gap: var(--space-s-m);
    text-wrap: balance;
    line-height: var(--leading-tight);

    &::before {
      content: "";
      background-image: url("/assets/images/quote.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      position: relative;
      display: block;
      top: 0.5rem;
      flex-shrink: 0;
    }

    @media (--md) {
      line-height: unset;

      &::before {
        top: 0;
        width: 3rem;
        height: 3rem;
      }
    }
  }
}

:where(.prose-sm) {
  font-size: var(--text-step-0);
}

:where(p) :where(a) {
  background-color: var(--link-bg, var(--color-beige));
  text-decoration: none;
  vertical-align: baseline;

  padding-inline: 0.5em 0.75em;
  padding-block: 0.1em 0.2em;
  border-radius: 99px;
  position: relative;
  white-space: nowrap;

  &::before {
    vertical-align: middle;
    content: "";
    display: inline-block;
    padding-inline: 0.25em;
    background-color: currentColor;
    inline-size: 1em;
    block-size: 1em;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' fill-rule='evenodd' d='M12.0206 4.46484 19.6946 12l-7.674 7.5351-1.0509-1.0703 5.8201-5.7148H3.34912v-1.5H16.7898l-5.8201-5.71486 1.0509-1.0703Z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
    mask-size: contain;
    mask-position: center;
    margin-block-end: 0.05em;
    margin-inline-end: 0.25em;
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue-dark);
    /* outline-offset: 2px; */
  }

  /* inside prose, links are not underlined */
  :where(.prose) & {
    background-color: var(--link-bg, var(--color-beige));
  }
}

.max-w-prose {
  max-inline-size: 70ch;
}
