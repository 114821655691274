.step {
  display: flex;
  column-gap: var(--space-xl);
  row-gap: var(--space-s);
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (--sm) {
    flex-direction: row;
  }

  & svg {
    color: var(--color-olive);
    inline-size: clamp(4rem, 0.5rem + 10vw, 8rem);
    aspect-ratio: 1;
    flex-shrink: 0;
  }
}

.steps__card {
  padding: var(--space-l);
  background-color: var(--color-beige);
  border-radius: 3rem 3rem 3rem 0;
  margin-block-start: var(--space-3xl);
  position: sticky;
  top: calc(var(--header-size) + 6rem);

  & svg {
    width: 3rem;
    aspect-ratio: 1;
  }
}
