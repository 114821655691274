.button {
  --_fallback-color: var(--color-blue-dark);

  font-size: var(--text-step--1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  block-size: 2.75rem;
  padding-inline: 1.25em;
  border-radius: 99px;
  border: 2px solid var(--color, var(--_fallback-color));
  color: var(--color, var(--_fallback-color));
  min-inline-size: 5rem;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-property: background-color, color;
  letter-spacing: var(--tracking-s);
  text-decoration: none;
  text-align: center;
  flex-shrink: 0;
  line-height: 1;

  &:hover,
  &:focus-visible {
    background-color: var(--color, var(--_fallback-color));
    color: var(--hover-color, var(--color-white));
  }

  &:focus-visible {
    outline: 2px solid var(--color, var(--_fallback-color));
    outline-offset: 2px;
  }

  &.big {
    block-size: 4em;
    padding-inline: var(--space-l-xl);
    font-size: var(--text-step-0);
  }

  &.primary {
    background: var(--color-olive);
    color: var(--color-white);
    border: var(--color-olive);
  }
}

:where(.bg-olive) {
  .button.secondary {
    color: var(--color-white);
    border-color: var(--color-white);

    &:hover,
    &:focus-visible {
      color: var(--color-olive);
      background: var(--color-white);
    }
  }

  .button.primary {
    background: var(--color-white);
    color: var(--color-olive);
    border-color: var(--color-white);
  }
}

:where(.bg-beige) {
  .button.secondary {
    color: var(--color-olive);
    border-color: var(--color-olive);

    &:hover,
    &:focus-visible {
      color: var(--color-white);
      background: var(--color-olive);
    }
  }

  .button.primary {
    background: var(--color-olive);
    color: var(--color-white);
    border-color: var(--color-olive);
  }
}

:where(.bg-blue-dark) {
  .button.secondary {
    color: var(--color-white);
    border-color: var(--color-white);

    &:hover,
    &:focus-visible {
      color: var(--color-blue-dark);
      background: var(--color-white);
    }
  }

  .button.primary {
    background: var(--color-blue-light);
    color: var(--color-blue-dark);
    border-color: var(--color-blue-light);
  }
}

:where(.bg-blue-light) {
  .button.primary {
    background: var(--color-beige);
    color: var(--color-blue-dark);
    border-color: var(--color-beige);

    &:hover,
    &:focus-visible {
      color: var(--color-white);
      background: var(--color-blue-dark);
    }
  }
}
