.cta {
  text-align: center;
  padding-block: var(--space-3xl-4xl);
  position: relative;
  isolation: isolate;

  > div > * {
    margin-inline: auto;
  }

  h2 {
    max-inline-size: 40ch;
    text-wrap: balance;
  }

  > svg {
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
}

.cta__button {
  font-size: var(--text-step-0);
  font-weight: var(--weight-bold);
  letter-spacing: var(--tracking-s);
  text-transform: uppercase;
  padding-inline: 2.5em;
  padding-block: 1em;
  background-color: var(--color-beige);
  display: inline-block;
  text-decoration: none;
  border-radius: 99px;
  border-bottom-left-radius: 0;
  transition-duration: 0.2s;
  transition-property: background-color, color;

  &:hover,
  &:focus-visible {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
  }

  &:focus-visible {
    outline: 2px solid var(--color-blue-dark);
    outline-offset: 2px;
  }
}

.cta__left-illu {
  aspect-ratio: 183 / 399;
  top: var(--space-3xl);
  left: 0;
  width: max(10rem, 12vw);
}

.cta__right-illu {
  aspect-ratio: 264 / 260;
  width: max(15rem, 18vw);
  bottom: 0;
  right: 0;
}
