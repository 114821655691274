/* A modified version of my "modern reset" https://github.com/hankchizljaw/modern-css-reset */

* {
  scroll-margin-block-start: var(--header-size);
}

/* Box sizing rules */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Allow percentage-based heights in the application*/
html,
body {
  min-height: 100%;
}

/* Set core root defaults */
html {
  /* scroll-behavior: smooth; */
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Default link styles */
a {
  text-decoration-skip-ink: auto;
  color: inherit;
}

/* Make images easier to work with */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
summary,
select {
  font: inherit;
}

button {
  background-color: transparent;
  border: 0;
  color: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul:where([role="list"]),
ol:where([role="list"]) {
  list-style-type: none;
  padding: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *:before,
  *:after {
    animation-duration: 0.01s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01s !important;
    scroll-behavior: auto !important;
  }
}

::selection {
  color: var(--color-beige);
  background-color: var(--color-blue-dark);
}
