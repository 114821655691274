@layer fonts, reset, layout, plugins, utils, blocks;

@import "./_fonts.css" layer(fonts);

@import "./_media.css";
@import "./_reset.css" layer(reset);

@import-glob "./blocks/**/_*.css" layer(blocks);
@import-glob "./layout/**/_*.css" layer(layout);
@import-glob "./utilities/**/_*.css" layer(utils);

@import "tailwindcss/base" layer(reset);
@import "tailwindcss/components" layer(layout);
@import "tailwindcss/utilities" layer(utils);

@import "@splidejs/splide/dist/css/splide-core.min.css" layer(plugins);

body {
  font-family: "Wix Madefor Text Variable", sans-serif;
  color: var(--color-blue-dark);
  background-color: var(--color-white);
}

button {
  cursor: pointer;
}
