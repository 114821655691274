/* This file defines our media queries */

/* 400px */
@custom-media --xs (width >= 25em);
@custom-media --xs-max (width < 25em);

/* 640px */
@custom-media --sm (width >= 40em);
@custom-media --sm-max (width < 40em);

/* 768px */
@custom-media --md (width >= 48em);
@custom-media --md-max (width < 48em);

/* 1024px */
@custom-media --lg (width >= 64em);
@custom-media --lg-max (width < 64em);

/* 1200px */
@custom-media --xl (width >= 75em);
@custom-media --xl-max (width < 75em);
