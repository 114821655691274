.key-facts {
  text-align: center;

  > h2,
  > p {
    margin-inline: auto;
    max-inline-size: 38ch;
  }
}

.key-facts__list {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-l-xl);
  align-items: center;
  justify-content: center;

  > li {
    flex: 1;
  }

  @media (--sm) {
    flex-direction: row;
    align-items: baseline;
  }
}

.key-facts__item {
  text-align: center;
  font-size: var(--text-step-1);
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  align-items: center;
  justify-content: center;
  font-weight: var(--weight-medium);
  max-width: 18ch;
  white-space: nowrap;
}
