.card {
  border-radius: 1.25rem;
  padding: var(--space-m);
}

.card--beige {
  --color: var(--color-olive-text);
  color: var(--color);
  background-color: var(--color-beige);
}

.card--olive {
  --color: var(--color-white);
  color: var(--color);
  background-color: var(--color-olive);
}

.card--blue-light {
  --color: var(--color-blue-dark);
  color: var(--color);
  background-color: var(--color-blue-light);
}

.card--blue-dark {
  --color: var(--color-white);
  color: var(--color);
  background-color: var(--color-blue-dark);
}
