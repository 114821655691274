/**
 * WRAPPER
 * Sets a max width, adds a consisten gutter and horizontally
 * centers the contents
 */
.wrapper {
  width: min(100% - var(--space-s-m) * 2, var(--wrapper-max, 82rem));
  margin-inline: auto;
  position: relative;
}
