.publications {
  display: flex;
  flex-direction: column;

  @media (min-width: 80rem) {
    flex-direction: row;
  }

  .sticky {
    position: sticky;
    top: 10rem;

    p {
      max-width: 40ch;
    }
  }
}
