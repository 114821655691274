.blog-hero {
  align-items: center;
  padding-block: var(--space-m-l) var(--space-xl-2xl);
  --radius: 2.5rem;

  & .blog-hero__content {
    text-wrap: balance;
  }

  & .blog-hero__visual {
    position: relative;

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 6/5;
      object-fit: cover;
      background-position: center;
      border-radius: var(--radius);
    }

    &::before {
      position: absolute;
      content: "Top News";
      background: var(--color-olive);
      color: var(--color-white);
      font-size: var(--text-step-0);
      font-weight: var(--weight-bold);
      text-transform: uppercase;
      padding: 0 var(--space-xs-s);
      top: var(--space-m-l);
      left: var(--space-m-l);
    }

    .blog-hero__action {
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--color-white);
      padding: var(--space-l) var(--space-l) 0 0;
      border-radius: 0 var(--radius) 0 0;

      &::before,
      &::after {
        content: "";
        width: var(--radius);
        height: var(--radius);
        background: radial-gradient(
          200% 200% at 100% 0%,
          rgba(255, 0, 0, 0) 50%,
          #ffffff 50%
        );
        position: absolute;
      }

      &::before {
        bottom: 100%;
        left: 0;
      }

      &::after {
        bottom: 0;
        left: 100%;
      }
    }
  }

  & .icon-info a {
    text-decoration: none;
    font-style: normal;
  }
}
