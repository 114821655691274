.icon-text {
  display: flex;
  align-items: center;
  gap: var(--space-2xs);
  font-size: var(--text-step-0);
  font-style: normal;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}
