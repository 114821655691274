.corner {
  --_corner-size: var(--corner-size, 4rem);
  inline-size: var(--_corner-size);
  block-size: var(--_corner-size);
  display: block;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: -100%;
    right: -100%;
    border-radius: 50%;
    inline-size: 200%;
    block-size: 200%;
    outline: var(--corner-size) solid var(--corner-color, gray);
  }
}
