.building-blocks {
  & h2 {
    max-inline-size: 30ch;

    & span {
      display: inline-block;
    }
  }

  & figure {
    position: sticky;
    top: calc(var(--header-size) + 6rem);
  }
}

.building-block {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2xs);
  align-items: baseline;

  & h3 {
    flex-basis: 12ch;
    flex-shrink: 0;
  }

  > div {
    flex-basis: 60%;
    flex-grow: 1;
  }
}
