.post__content {
  h6 {
    color: var(--color-olive-text);
    font-size: var(--text-step-3);
    font-weight: var(--weight-regular);
    display: flex;
    gap: var(--space-s-m);
    text-wrap: balance;
    line-height: var(--leading-tight);

    &::before {
      content: "";
      background-image: url("/assets/images/text-highlight.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 2rem;
      height: 2rem;
      position: relative;
      display: block;
      top: 0.5rem;
      flex-shrink: 0;
    }

    @media (--md) {
      line-height: unset;

      &::before {
        width: 3rem;
        height: 3rem;
      }
    }
  }

  figure {
    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      width: clamp(10ch, 80%, 60ch);
      margin-inline: auto;
      font-size: var(--text-step-0);
      font-weight: var(--weight-bold);
    }
  }
}
