.references {
  --wrapper-max: 58rem;
  padding-inline: var(--space-xs-xl);
}

.references__slider {
  & .splide__list {
    align-items: center;
  }

  & .splide__arrows {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    pointer-events: none;
    /* margin-inline: calc(-1 * clamp(-1.5rem, -2.824rem + 5.65vw, 0rem)); */
    margin-inline: calc(-1 * var(--space-xs-2xl));
  }

  & .splide__arrow {
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: var(--space-l-xl);
    aspect-ratio: 1;
    border-radius: 99px;
    background-color: var(--color-white);

    & svg {
      width: 75%;
      color: var(--color-olive);
      aspect-ratio: 1;
    }
  }

  & .splide__pagination {
    margin-top: var(--space-s);
    gap: var(--space-2xs);
  }

  & .splide__pagination__page {
    width: var(--space-xs);
    height: var(--space-xs);
    border-radius: 50%;
    background-color: var(--color-blue-dark);
    cursor: pointer;
    opacity: 0.2;
    transition: all 0.2s;

    &[aria-selected="true"] {
      opacity: 1;
      border-radius: 0;
    }
  }
}
