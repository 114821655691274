.review {
  display: flex;
  --radius: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media (--md) {
    --radius: 2.5rem;
    flex-direction: row;
  }

  @media (--md-max) {
    & p {
      hyphens: auto;
    }
  }
}

.review__image {
  flex-shrink: 0;
  position: relative;

  &::after {
    content: "";
    width: var(--radius);
    height: var(--radius);
    background: radial-gradient(
      200% 200% at 100% 0%,
      rgba(164, 188, 225, 0) 50%,
      #a4bce1 50%
    );
    position: absolute;
    bottom: 0;
    right: calc(var(--radius) * 2);
    transform: rotate(-90deg);
    opacity: 0;
  }

  @media (--md) {
    &::after {
      opacity: 1;
    }
  }
}

.review__portrait {
  width: clamp(12rem, 40vw, 25rem);
  max-width: 100%;

  img {
    aspect-ratio: 1.2;
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: var(--radius) var(--radius) var(--radius) 0;

    @media (--md) {
      border-radius: var(--radius) 0 var(--radius) var(--radius);
    }
  }
}

.review__quote-icon {
  position: absolute;
  top: 0;
  right: calc(var(--radius) * -1.5);
}

.review__logo {
  position: absolute;
  padding: 0.4rem;
  background: var(--color-white);
  border-radius: 999px;
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
  bottom: -1rem;
  left: var(--space-l);
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
  }
}

.review blockquote {
  position: relative;
  padding: var(--radius);

  @media (--md) {
    margin-left: calc(var(--radius) * -1);
    margin-top: var(--radius);

    p {
      font-size: var(--text-step-1);
    }
  }

  &::before {
    content: "";
    width: var(--radius);
    height: var(--radius);
    background: radial-gradient(
      200% 200% at 100% 0%,
      rgba(164, 188, 225, 0) 50%,
      #a4bce1 50%
    );
    position: absolute;
  }

  &::before {
    top: calc(var(--radius) * -1);
    left: 0;
    transform: rotate(-90deg);
    opacity: 0;
  }

  &::after {
    content: "";
    height: 100%;
    width: var(--radius);
    position: absolute;
    top: 0;
    right: 100%;
    border-radius: var(--radius) 0 0 0;
    background: var(--color-blue-light);
    opacity: 0;
  }

  @media (--md) {
    &::after,
    &::before {
      opacity: 1;
    }
  }
}
