.blog-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  transition: box-shadow 0.2s, background-color 0.2s;
  border-radius: var(--radius-m);

  &:is(:hover, :focus-visible) {
    box-shadow: 0 0 0 1rem var(--color-white);
    background-color: var(--color-white);
  }

  & .blog-card__image {
    & img {
      width: 100%;
      height: auto;
      aspect-ratio: 5/3;
      object-fit: cover;
      border-radius: var(--radius-m);
    }
  }

  & a {
    &::before {
      inset: 0;
      content: "";
      position: absolute;
    }
  }
}
