.team-member {
  position: relative;
  display: flex;
  flex-direction: column;

  @media (min-width: 80rem) and (min-height: 40rem) {
    flex-direction: row;
  }
}

.team-member__summary {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: var(--space-m-l);

  .team-member__picture {
    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      max-width: 20rem;
      height: auto;
      aspect-ratio: 3/4;
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: var(--space-xl);
    height: 100%;
    background: var(--color-beige);
  }

  @media (min-width: 80rem) and (min-height: 40rem) {
    height: 100vh;
    position: sticky;
    width: clamp(20rem, 40vw, 40rem);
    flex-shrink: 0;
    padding: var(--space-l-xl);
  }
}

.team-member__summary-content {
  max-width: 50ch;
}

.team-member__content {
  padding: var(--space-xl) var(--space-m);

  @media (min-width: 40rem) {
    padding: var(--space-2xl);
  }
}

.next-team-member {
  position: relative;
  display: flex;
  flex-direction: column;
  --radius: 2.5rem;
  padding-block: var(--space-l) var(--space-xl);
  padding-inline: var(--space-m);

  .next-team-member__image {
    aspect-ratio: 3/4;
    width: 100%;

    img {
      border-radius: var(--radius) var(--radius) var(--radius) 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .next-team-member__content {
    position: relative;
    background: var(--color-blue-light);
    border-radius: var(--radius) 0 0 0;
    margin-top: var(--space-s);
  }

  @media (min-width: 30rem) {
    display: grid;
    grid-template-columns: clamp(10rem, 20vw, 20rem) 1fr;
    gap: var(--space-m);
  }
}
