.footer {
  ::selection {
    background-color: var(--color-white);
    color: var(--color-blue-dark);
  }
}

.footer__home-link {
  display: inline-block;
}

.footer__main {
  padding-block: var(--space-xl-2xl);
  background-color: var(--color-blue-dark);
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  color: var(--color-white);

  & a:not(.button) {
    text-decoration: none;

    &:focus-visible {
      outline: 2px solid var(--color-white);
      outline-offset: 2px;
    }
  }

  & svg {
    width: 17.5rem;
    height: 3rem;
  }
}

.footer__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--space-xl);
}

.footer__contact {
  flex-basis: 44.5rem;
  flex-shrink: 1.5;
}

.footer__contact-info {
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  gap: var(--space-l);

  > div {
    display: flex;
    flex-wrap: wrap;
    column-gap: inherit;
    row-gap: var(--space-s-m);

    > * {
      flex: 1;
      white-space: nowrap;
    }
  }

  & address {
    font-style: normal;
  }
}

.footer__cta {
  padding: var(--space-s);
  border-radius: 3rem 3rem 3rem 0;
  display: flex;
  gap: var(--space-m);
  color: var(--color-blue-dark);
  align-items: flex-start;
  flex-wrap: wrap;

  > img {
    width: var(--space-2xl-3xl);
    /* flex-basis: ; */
    aspect-ratio: 1;
    border-radius: 99px;
    flex-shrink: 0;
  }

  > div {
    flex-basis: 19rem;
    flex-grow: 1;
  }
}

.footer__legal {
  padding-block: var(--space-s);

  & :is(a, button):not(.button) {
    text-decoration: none;

    &:focus-visible {
      outline: 2px solid var(--color-blue-dark);
      outline-offset: 2px;
    }
  }
}

.footer__copyright {
  opacity: 0.4;
}
