.anchor-nav {
  --cluster-justification: center;
  --cluster-gap: var(--space-xs);
  z-index: 20;

  padding-block: var(--space-xs);
  position: sticky;
  top: var(--header-size);
  margin-bottom: var(--space-3xl);
  transition: background-color 0.3s, backdrop-filter 0.3s;
  background-color: transparent;

  &[data-stuck] {
    backdrop-filter: blur(0.5rem);
    background-color: color-mix(in srgb, var(--color-white), transparent 10%);
  }

  & a {
    font-size: 1.125rem;

    @media (--md) {
      font-size: var(--text-step-1);
    }
  }
}
