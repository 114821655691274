.post-header__visual {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68%;
    background: var(--color-blue-dark);
    position: absolute;
    bottom: 0;
    left: 0;
  }

  img {
    border-radius: var(--radius-m);
    width: clamp(30rem, 80vw, 50rem);
    height: auto;
    aspect-ratio: 5/3;
    object-fit: cover;
  }
}
